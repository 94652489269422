:host {
    --day-width: 36px;
    --day-height: 30px;
    --border-radius: 2px;
}

@media screen and (max-width: 480px) {
	:host {
		--day-width: 40px;
		--day-height: 35px;
	}
}

* {
    box-sizing: border-box;
}

.container {
    display: inline-block;
    border-radius: 4px;
    overflow: hidden;
    transform: scale(0);
    height: 0;
    font-family: var(--bs-body-font-family);
    font-size: 0.8em;
    cursor: default;
    color: var(--bs-body-color);
    pointer-events: all;
    position: absolute;

    &.h-right {
        transform: translate(-100%, 0) !important;
    }
    &.h-center {
        transform: translate(-50%, 0) !important;
    }
    &.v-top {
        transform: translate(0, -100%) !important;
        &.h-right {
            transform: translate(-100%, -100%) !important;
        }
        &.h-center {
            transform: translate(-50%, -100%) !important;
        }
    }
    &.v-center {
        transform: translate(0, -50%) !important;
        &.h-right {
            transform: translate(-100%, -50%) !important;
        }
        &.h-center {
            transform: translate(-50%, -50%) !important;
        }
    }

    &.calc {
        transition: none;
        height: auto;
        visibility: hidden;
        transform: none;
    }

    &.show {
        transform: scale(1);
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
        height: auto;
    }

    &.inline {
        position: relative;
        top: 0px;
        left: 0px;
        box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
        height: auto;
        transform: scaleY(1);
    }

    >main {
        background-color: var(--bs-body-bg);
    }

    >header {
        padding: 5px 10px;
        background-color: var(--bs-secondary-bg-subtle);
    }
}

@for $i from 1 through 12 {
    .grid-#{$i} {
        grid-template-columns: repeat($i, 1fr);
    }
}

.calendars {
    display: grid;

    &:not(.grid-1) {
        .calendar {
            >.header {
                .month-name {
                    order: 2;
                    text-align: center;
                }

                .previous-button {
                    order: 1;
                    visibility: hidden;
                }

                .next-button {
                    order: 3;
                    visibility: hidden;
                }
            }

            &:first-child {
                >.header {
                    .previous-button {
                        visibility: visible;
                    }
                }
            }

            &:last-child {
                >.header {
                    .next-button {
                        visibility: visible;
                    }
                }
            }
        }
    }
}

.calendar {
    padding: 5px;

    >.header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 5px;

        .month-name {
            flex: 1;
            font-weight: 500;
            font-size: 15px;

            >span {
                font-weight: bold;
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid transparent;
            background-color: transparent;
            color: var(--bs-body-color);
            padding: 10px;
            border-radius: 2px;
            cursor: pointer;

            &:hover {
                background-color: var(--bs-secondary-bg-subtle);

                >svg,
                >img {
                    color: var(--bs-primary);
                    fill: var(--bs-primary);
                }
            }

            >svg,
            >img {
                pointer-events: none;
				color: var(--bs-body-color);
                fill: var(--bs-body-color);
                transform: scale(0.7);
            }
        }
    }

    >.days-grid,
    >.daynames-row {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        row-gap: 2px;

        >.day,
        >.dayname {
            cursor: default;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: 13px;
        }
    }

    >.daynames-row>.dayname {
        padding: 5px 0;
        color: var(--bs-secondary-text-emphasis);
        font-size: 12px;
    }

    >.days-grid>.day {
        padding: 10px 0;
        border: 1px solid transparent;
        border-radius: 2px;
        width: var(--day-width);
        min-width: var(--day-width);
        max-width: var(--day-width);
        height: var(--day-height);
        min-height: var(--day-height);
        max-height: var(--day-height);

        &:hover {
            border: 1px solid var(--bs-primary);
            color: var(--bs-primary);
        }

        &.today {
            color: var(--bs-primary);
        }

        &.selected {
            background-color: var(--bs-primary);
            color: var(--bs-body-bg);
        }
    }

}

@media (max-width: 480px) {
    .container:not(.inline) {
        transform: scaleY(0) !important;
        transform-origin: bottom center !important;

        &.show {
            transform: scaleY(1) !important;
        }
    }
}

.container.lock-plugin {
    .calendars {
        .calendar {
            &:first-child {
                >.header {
                    &.no-previous-month {
                        .previous-button {
                            visibility: hidden;
                        }
                    }
                }
            }

            &:last-child {
                >.header {
                    &.no-next-month {
                        .next-button {
                            visibility: hidden;
                        }
                    }
                }
            }
        }
    }

    .calendar {
        >.days-grid {
            >.day {
                &.not-available {
                    pointer-events: none;
                    background-color: var(--bs-border-color);
                    color: var(--bs-gray-600);
                    font-style: italic;
                }

                &.locked {
                    color: var(--bs-secondary-text-emphasis);
                    background-color: transparent;
                    border: 1px solid var(--bs-border-color);
                    pointer-events: none;

                    &:not(.start):not(.end) {
                        background-image: repeating-linear-gradient(135deg,
                                transparent,
                                var(--bs-secondary-text-emphasis) 2px,
                                transparent 2px,
                                transparent 4px);
                        font-style: italic;
                    }
                }
            }
        }
    }

    .preset-plugin-container {
        >button {
            &:disabled {
                pointer-events: none;
                color: var(--bs-secondary-text-emphasis)
            }
        }
    }
}

.range-plugin-tooltip {
    position: absolute;
    margin-top: -4px;
    padding: 4px 8px;
    border-radius: var(--border-radius);
    background-color: var(--bs-body-bg);
    color: var(--bs-body-color);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    white-space: nowrap;
    font-size: 12px;
    pointer-events: none;
    visibility: hidden;
    z-index: 1;

    &:before {
        position: absolute;
        bottom: -5px;
        left: calc(50% - 5px);
        border-top: 5px solid rgba(0, 0, 0, 0.12);
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        content: "";
    }

    &:after {
        position: absolute;
        bottom: -4px;
        left: calc(50% - 4px);
        border-top: 4px solid var(--bs-body-bg);
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: "";
    }
}

.container.range-plugin {
    .calendar {
        >.days-grid {
            >.day {
                position: relative;

                &.in-range {
                    &:last-of-type {
                        border-top-right-radius: var(--border-radius);
                        border-bottom-right-radius: var(--border-radius);
                    }
                }

                &.in-range {
                    border-radius: 0;
                    background-color: var(--bs-primary-bg-subtle);
                }

                &.start,
                &.end {
                    background-color: var(--bs-primary);
                    color: var(--bs-body-bg);
                }

                &.start {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;

                    &:after {
                        content: "";
                        position: absolute;
                        border: 8px solid var(--bs-primary);
                        border-top-color: transparent;
                        border-bottom-color: transparent;
                        border-right-color: transparent;
                        right: -14px;
                        /*top: 11px;*/
                        z-index: 1;
                        pointer-events: none;
                    }

                    &.flipped {
                        border-top-right-radius: var(--border-radius);
                        border-bottom-right-radius: var(--border-radius);
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;

                        &:after {
                            right: auto;
                            left: -14px;
                            border-right-color: var(--bs-primary);
                            border-left-color: transparent;
                        }
                    }
                }

                &.end {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;

                    &:after {
                        content: "";
                        position: absolute;
                        border: 8px solid var(--bs-primary);
                        border-top-color: transparent;
                        border-bottom-color: transparent;
                        border-left-color: transparent;
                        left: -14px;
                        /*top: 11px;*/
                        z-index: 1;
                        pointer-events: none;
                    }

                    &.flipped {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        border-top-left-radius: var(--border-radius);
                        border-bottom-left-radius: var(--border-radius);

                        &:after {
                            left: auto;
                            right: -14px;
                            border-left-color: var(--bs-primary);
                            border-right-color: transparent;
                        }
                    }
                }

                &.start.end {
                    border-radius: var(--border-radius);

                    &:after {
                        content: none;
                    }
                }
            }

            >div {
                &:not(.day)+.day.in-range {
                    border-top-left-radius: var(--border-radius);
                    border-bottom-left-radius: var(--border-radius);
                }

                &:nth-child(7n) {
                    &.in-range {
                        border-top-right-radius: var(--border-radius);
                        border-bottom-right-radius: var(--border-radius);
                    }
                }

                &:nth-child(7n + 1) {
                    &.in-range {
                        border-top-left-radius: var(--border-radius);
                        border-bottom-left-radius: var(--border-radius);
                    }
                }
            }
        }
    }
}

.container.amp-plugin {
    .calendars {
        .calendar {
            >.header {
                .month-name {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 5px;

                    select {
                        font-size: 14px;
                        padding: 5px;
                        border: none;
                    }
                }

                .reset-button {
                    order: 4;
                }
            }
        }

        &.calendars:not(.grid-1) {
            .calendar {
                >.header {
                    .reset-button {
                        visibility: hidden;
                    }
                }

                &:last-child {
                    >.header {
                        .reset-button {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}