:host {
  --day-width: 36px;
  --day-height: 30px;
  --border-radius: 2px;
}

@media screen and (max-width: 480px) {
  :host {
    --day-width: 40px;
    --day-height: 35px;
  }
}
* {
  box-sizing: border-box;
}

.container {
  display: inline-block;
  border-radius: 4px;
  overflow: hidden;
  transform: scale(0);
  height: 0;
  font-family: var(--bs-body-font-family);
  font-size: 0.8em;
  cursor: default;
  color: var(--bs-body-color);
  pointer-events: all;
  position: absolute;
}
.container.h-right {
  transform: translate(-100%, 0) !important;
}
.container.h-center {
  transform: translate(-50%, 0) !important;
}
.container.v-top {
  transform: translate(0, -100%) !important;
}
.container.v-top.h-right {
  transform: translate(-100%, -100%) !important;
}
.container.v-top.h-center {
  transform: translate(-50%, -100%) !important;
}
.container.v-center {
  transform: translate(0, -50%) !important;
}
.container.v-center.h-right {
  transform: translate(-100%, -50%) !important;
}
.container.v-center.h-center {
  transform: translate(-50%, -50%) !important;
}
.container.calc {
  transition: none;
  height: auto;
  visibility: hidden;
  transform: none;
}
.container.show {
  transform: scale(1);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  height: auto;
}
.container.inline {
  position: relative;
  top: 0px;
  left: 0px;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  height: auto;
  transform: scaleY(1);
}
.container > main {
  background-color: var(--bs-body-bg);
}
.container > header {
  padding: 5px 10px;
  background-color: var(--bs-secondary-bg-subtle);
}

.grid-1 {
  grid-template-columns: repeat(1, 1fr);
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-6 {
  grid-template-columns: repeat(6, 1fr);
}

.grid-7 {
  grid-template-columns: repeat(7, 1fr);
}

.grid-8 {
  grid-template-columns: repeat(8, 1fr);
}

.grid-9 {
  grid-template-columns: repeat(9, 1fr);
}

.grid-10 {
  grid-template-columns: repeat(10, 1fr);
}

.grid-11 {
  grid-template-columns: repeat(11, 1fr);
}

.grid-12 {
  grid-template-columns: repeat(12, 1fr);
}

.calendars {
  display: grid;
}
.calendars:not(.grid-1) .calendar > .header .month-name {
  order: 2;
  text-align: center;
}
.calendars:not(.grid-1) .calendar > .header .previous-button {
  order: 1;
  visibility: hidden;
}
.calendars:not(.grid-1) .calendar > .header .next-button {
  order: 3;
  visibility: hidden;
}
.calendars:not(.grid-1) .calendar:first-child > .header .previous-button {
  visibility: visible;
}
.calendars:not(.grid-1) .calendar:last-child > .header .next-button {
  visibility: visible;
}

.calendar {
  padding: 5px;
}
.calendar > .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
}
.calendar > .header .month-name {
  flex: 1;
  font-weight: 500;
  font-size: 15px;
}
.calendar > .header .month-name > span {
  font-weight: bold;
}
.calendar > .header button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--bs-body-color);
  padding: 10px;
  border-radius: 2px;
  cursor: pointer;
}
.calendar > .header button:hover {
  background-color: var(--bs-secondary-bg-subtle);
}
.calendar > .header button:hover > svg,
.calendar > .header button:hover > img {
  color: var(--bs-primary);
  fill: var(--bs-primary);
}
.calendar > .header button > svg,
.calendar > .header button > img {
  pointer-events: none;
  color: var(--bs-body-color);
  fill: var(--bs-body-color);
  transform: scale(0.7);
}
.calendar > .days-grid,
.calendar > .daynames-row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 2px;
}
.calendar > .days-grid > .day,
.calendar > .days-grid > .dayname,
.calendar > .daynames-row > .day,
.calendar > .daynames-row > .dayname {
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
}
.calendar > .daynames-row > .dayname {
  padding: 5px 0;
  color: var(--bs-secondary-text-emphasis);
  font-size: 12px;
}
.calendar > .days-grid > .day {
  padding: 10px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  width: var(--day-width);
  min-width: var(--day-width);
  max-width: var(--day-width);
  height: var(--day-height);
  min-height: var(--day-height);
  max-height: var(--day-height);
}
.calendar > .days-grid > .day:hover {
  border: 1px solid var(--bs-primary);
  color: var(--bs-primary);
}
.calendar > .days-grid > .day.today {
  color: var(--bs-primary);
}
.calendar > .days-grid > .day.selected {
  background-color: var(--bs-primary);
  color: var(--bs-body-bg);
}

@media (max-width: 480px) {
  .container:not(.inline) {
    transform: scaleY(0) !important;
    transform-origin: bottom center !important;
  }
  .container:not(.inline).show {
    transform: scaleY(1) !important;
  }
}
.container.lock-plugin .calendars .calendar:first-child > .header.no-previous-month .previous-button {
  visibility: hidden;
}
.container.lock-plugin .calendars .calendar:last-child > .header.no-next-month .next-button {
  visibility: hidden;
}
.container.lock-plugin .calendar > .days-grid > .day.not-available {
  pointer-events: none;
  background-color: var(--bs-border-color);
  color: var(--bs-gray-600);
  font-style: italic;
}
.container.lock-plugin .calendar > .days-grid > .day.locked {
  color: var(--bs-secondary-text-emphasis);
  background-color: transparent;
  border: 1px solid var(--bs-border-color);
  pointer-events: none;
}
.container.lock-plugin .calendar > .days-grid > .day.locked:not(.start):not(.end) {
  background-image: repeating-linear-gradient(135deg, transparent, var(--bs-secondary-text-emphasis) 2px, transparent 2px, transparent 4px);
  font-style: italic;
}
.container.lock-plugin .preset-plugin-container > button:disabled {
  pointer-events: none;
  color: var(--bs-secondary-text-emphasis);
}

.range-plugin-tooltip {
  position: absolute;
  margin-top: -4px;
  padding: 4px 8px;
  border-radius: var(--border-radius);
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  font-size: 12px;
  pointer-events: none;
  visibility: hidden;
  z-index: 1;
}
.range-plugin-tooltip:before {
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
  border-top: 5px solid rgba(0, 0, 0, 0.12);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: "";
}
.range-plugin-tooltip:after {
  position: absolute;
  bottom: -4px;
  left: calc(50% - 4px);
  border-top: 4px solid var(--bs-body-bg);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}

.container.range-plugin .calendar > .days-grid > .day {
  position: relative;
}
.container.range-plugin .calendar > .days-grid > .day.in-range:last-of-type {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.container.range-plugin .calendar > .days-grid > .day.in-range {
  border-radius: 0;
  background-color: var(--bs-primary-bg-subtle);
}
.container.range-plugin .calendar > .days-grid > .day.start, .container.range-plugin .calendar > .days-grid > .day.end {
  background-color: var(--bs-primary);
  color: var(--bs-body-bg);
}
.container.range-plugin .calendar > .days-grid > .day.start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.container.range-plugin .calendar > .days-grid > .day.start:after {
  content: "";
  position: absolute;
  border: 8px solid var(--bs-primary);
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  right: -14px;
  /*top: 11px;*/
  z-index: 1;
  pointer-events: none;
}
.container.range-plugin .calendar > .days-grid > .day.start.flipped {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.container.range-plugin .calendar > .days-grid > .day.start.flipped:after {
  right: auto;
  left: -14px;
  border-right-color: var(--bs-primary);
  border-left-color: transparent;
}
.container.range-plugin .calendar > .days-grid > .day.end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.container.range-plugin .calendar > .days-grid > .day.end:after {
  content: "";
  position: absolute;
  border: 8px solid var(--bs-primary);
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  left: -14px;
  /*top: 11px;*/
  z-index: 1;
  pointer-events: none;
}
.container.range-plugin .calendar > .days-grid > .day.end.flipped {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.container.range-plugin .calendar > .days-grid > .day.end.flipped:after {
  left: auto;
  right: -14px;
  border-left-color: var(--bs-primary);
  border-right-color: transparent;
}
.container.range-plugin .calendar > .days-grid > .day.start.end {
  border-radius: var(--border-radius);
}
.container.range-plugin .calendar > .days-grid > .day.start.end:after {
  content: none;
}
.container.range-plugin .calendar > .days-grid > div:not(.day) + .day.in-range {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.container.range-plugin .calendar > .days-grid > div:nth-child(7n).in-range {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.container.range-plugin .calendar > .days-grid > div:nth-child(7n+1).in-range {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.container.amp-plugin .calendars .calendar > .header .month-name {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}
.container.amp-plugin .calendars .calendar > .header .month-name select {
  font-size: 14px;
  padding: 5px;
  border: none;
}
.container.amp-plugin .calendars .calendar > .header .reset-button {
  order: 4;
}
.container.amp-plugin .calendars.calendars:not(.grid-1) .calendar > .header .reset-button {
  visibility: hidden;
}
.container.amp-plugin .calendars.calendars:not(.grid-1) .calendar:last-child > .header .reset-button {
  visibility: visible;
}